var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-block-list-spot-box-container-custom',
		'c-block-list-spot-box-container-custom--wide',
		{
			'bg-sand py-lg -mx-layout-margin px-layout-margin':
				_vm.template != 'FrontPage',
		} ]},[(_vm.computedItems.length == 1)?_c('div',{staticClass:"w-12col flex"},[_c('LinkTile',{class:[
				'c-block-list-spot-box-container-custom__single',
				'hidden >=1024:flex flex-1 group',
				'overflow-hidden rounded-lg bg-white' ],attrs:{"to":_vm.computedItems[0].link[0].url}},[_c('div',{class:[
					'flex-1 py-xl px-lg',
					'flex flex-col justify-between' ]},[_c('div',[_c('div',{staticClass:"text-h8 uppercase mb-sm",domProps:{"textContent":_vm._s(_vm.computedItems[0].label)}}),_vm._v(" "),_c('BaseH1',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s(_vm.computedItems[0].title)}}),_vm._v(" "),_c('div',{staticClass:"mt-sm",domProps:{"innerHTML":_vm._s(_vm.computedItems[0].teaser)}})],1),_vm._v(" "),_c('BaseButton',{staticClass:"w-fit",attrs:{"aria-label":'Gå til' + _vm.computedItems[0].title ||
						_vm.computedItems[0].link[0].url},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgArrow')]},proxy:true}],null,false,1858925688)})],1),_vm._v(" "),_c('div',{staticClass:"w-6col flex-shrink-0 overflow-hidden",style:(("aspect-ratio: " + _vm.aspectRatio))},[(
						_vm.computedItems[0].image &&
						_vm.computedItems[0].image.cropUrl
					)?_c('UmbracoImageExt',{class:[
						'c-block-list-spot-box-container-custom__single-image',
						'duration-500 ease-smooth-out',
						'transform group-hover:scale-110' ],attrs:{"widths":[320, 480, 640, 480 * 2, 640 * 2],"alt":_vm.computedItems[0].image.altText,"source-url":_vm.computedItems[0].image.cropUrl,"source-width":_vm.computedItems[0].image.width,"source-height":_vm.computedItems[0].image.height,"aspect-ratio":_vm.aspectRatio}}):_vm._e(),_vm._v(" "),(
						_vm.computedItems[0].videoPicker &&
						_vm.computedItems[0].videoPicker.provider
					)?_c('CookieInformationBlocker',{staticClass:"w-full h-full",attrs:{"categories":_vm.computedItems[0].videoPicker.cookieCategories,"simple":""}},[_c('VideoPlayer',{staticClass:"w-full h-full",attrs:{"picker":_vm.computedItems[0].videoPicker}})],1):_vm._e()],1)]),_vm._v(" "),_vm._l((_vm.computedItems),function(item,index){return _c('ContentCard',{key:("item-" + index),staticClass:">=1024:hidden flex-1",attrs:{"image":Object.assign({}, (item.image || {}), {aspectRatio: _vm.aspectRatio}),"video":Object.assign({}, (item.videoPicker || {}), {aspectRatio: _vm.aspectRatio}),"to":item.link[0].url,"name":item.title,"size":"small","light":""},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(item.label)}})]},proxy:true},{key:"default",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(item.teaser)}})]},proxy:true}],null,true)})})],2):(_vm.computedItems.length)?_c('div',{class:[
			'flex flex-col >=1024:flex-row',
			'gap-x-layout-gutter gap-y-md w-12col' ]},_vm._l((_vm.computedItems),function(item,index){return _c('ContentCard',{key:("item-" + index),staticClass:"flex-1",attrs:{"image":Object.assign({}, (item.image || {}), {aspectRatio: _vm.aspectRatio}),"video":Object.assign({}, (item.videoPicker || {}), {aspectRatio: _vm.aspectRatio}),"to":item.link[0].url,"name":item.title,"size":"small","light":""},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(item.label)}})]},proxy:true},{key:"default",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(item.teaser)}})]},proxy:true}],null,true)})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }